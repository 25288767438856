import React from "react"
import Layout from "../components/Layout" 
import { graphql } from "gatsby"
import cv from "../../static/cv_full_fr.pdf"
import img from "../images/cv.jpg"

const CVPage = ({ data }) => {

  return (
    <>
      <Layout>

        <div className="page-wrapper cv">
          <div className="main-image">
            <img src={img}/>
          </div>
          <h1>À PROPOS</h1>
          <div className="details">
            <div className="breadcrumb">/NOW</div>
          </div>
          <br/>
          <ul>
            <li>Artiste Numerique Emergent</li> 
            <li>Directeur Artistique de la <a href="https://ciebeta.fr">compagnie Bêta</a></li> 
          </ul>
        
        <h1>BIOGRAPHIE</h1>
        <p>
        "Parallèlement à ces études de développement informatique et entreprenariats au sein de 42 et HEC (Digital entrepreneurship certificate); Il se forme au mouvement en autodidacte puis avec plusieurs compagnies émergentes circaciennes, urbaines & contemporaines. Il continuera sa recherche dansée entre le contemporain, le breakdance & l'acro-danse. Aujourd'hui il cherche de nouvelles manières d'hybrider mouvement et art numérique à travers la <a href="https://ciebeta.fr">compagnie Bêta</a>."
        </p>
        <div>
            <a className="button" href={cv}>Voir le CV</a>
        </div>
      </div>
      </Layout>
    </>
  )
}

export default CVPage

//export const query = graphql`
//  {
//    BlogPostQuery: allMarkdownRemark(
//      sort: { fields: [frontmatter___date], order: DESC }
//      limit: 3
//    ) {
//      totalCount
//      edges {
//        node {
//          frontmatter {
//            title
//            date
//            path
//            featuredImage {
//              childImageSharp {
//                gatsbyImageData(
//                  layout: FULL_WIDTH
//                  placeholder: TRACED_SVG
//                  formats: [AUTO, WEBP]
//                )
//              }
//            }
//            featuredImageAlt
//          }
//          excerpt
//        }
//      }
//    }
//  }
//`
